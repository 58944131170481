import { SET_MODAL, TOGGLE_TERMS_VISIBILITY } from '../actions/modal';

function initialState() {
  return { 'modalOpen': false };
}

function modalReducer(state = initialState(), { type, modal }) {
  switch (type) {
    case SET_MODAL:
      return {
        ...modal,
        termsVisible: false
      }
    case TOGGLE_TERMS_VISIBILITY:
      return {
        ...state,
        termsVisible: !state.termsVisible
      }
    default:
      return state;
  }
};

export default modalReducer;
