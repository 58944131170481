import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UKPriceChangeIcon from './price-change-icon.svg';
import EIREPriceChangeIcon from './price-change-icon.svg';


class PriceChangeIcon extends Component {

  render() {
    let Icon;

    if (this.props.zone == 'uk') {
      Icon = UKPriceChangeIcon
    } else {
      Icon = EIREPriceChangeIcon
    }

    return (
      <Icon/>
    );
  }

}

PriceChangeIcon.propTypes = {
  zone: PropTypes.string.isRequired
};

export default PriceChangeIcon;
