import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';

class SearchButton extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onClick', 'buttonStatus');
  }

  onClick() {
    if (this.props.bodyCode) {
      this.props.onClick();
    }
  }

  buttonStatus() {
    return this.props.buttonDisabled ? 'disabled' : '';
  }

  render() {
    return (
      <button className="search-button" onClick={ this.onClick } disabled={this.buttonStatus()}>
        Search results
      </button>
    );
  }
}

SearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired
};

const mapStateToProps = ({ bodyCode }) => {
  return {
    bodyCode
  }
};

export default connect(mapStateToProps)(SearchButton);
