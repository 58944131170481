import dispatcher from '../utils/action/dispatcher';
import productUpdatesApi from '../api/weekly-product-updates';

export const SET_WEEKLY_PRODUCT_UPDATES = 'SET_WEEKLY_PRODUCT_UPDATES';


export function get(bodyCode, productionDate, textSearch) {
  if ( bodyCode && productionDate ) {
    return dispatch => productUpdatesApi.fetch(bodyCode, productionDate, textSearch).then(dispatcher(dispatch, SET_WEEKLY_PRODUCT_UPDATES, 'weeklyProductUpdates'));
  } else{
    return set()
  }
}

export function set(weeklyProductUpdates = null) {
  return ({
    type: SET_WEEKLY_PRODUCT_UPDATES,
    weeklyProductUpdates
  });
}
