import { SET_TEXT_SEARCH } from '../actions/text-search';

function initialState() {
  return '';
}

function textSearchReducer(state = initialState(), { type, text }) {
  switch (type) {
    case SET_TEXT_SEARCH:
      return text;
    default:
      return state;
  }
};

export default textSearchReducer;
