import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import SearchCriteria from './search-criteria';
import TimelineNavigation from './timeline-navigation';
import ProductLibrary from './product-library';
import WeeklyUpdates from '../weekly-updates/index';
import classnames from 'classnames';
import {
  get as getWeeklyProductUpdates,
  set as setWeeklyProductUpdates }
  from '../../actions/weekly-product-updates';

class SearchResults extends Component {
  constructor(props) {
    super(props);

    bindAll(this,
      'setCurrentWeek',
      'defaultWeek',
      'prevResult',
      'nextResult'
    );

    this.state = {
      isSticky: false
    };
  }

  setCurrentWeek(currentWeekIndex) {
    const { bodyCode, searchResultsWeeks, textSearch } = this.props;
    const currentWeek = searchResultsWeeks && searchResultsWeeks[currentWeekIndex];

    this.setState({ currentWeekIndex, currentWeek });

    this.props.dispatch(getWeeklyProductUpdates(bodyCode, currentWeek, textSearch));
  }

  componentDidMount() {
    this.setCurrentWeek(this.defaultWeek(this.props.searchResultsWeeks))
  }

  componentDidUpdate(prevProps) {
    const { searchResultsWeeks } = prevProps;

    if ( searchResultsWeeks && (searchResultsWeeks != this.props.searchResultsWeeks) ) {
      this.setCurrentWeek(this.defaultWeek(searchResultsWeeks));
    }
  }

  defaultWeek(list) {
    return 0;
  }

  renderWeeklyUpdates() {
    const { weeklyProductUpdates } = this.props;

    if ( weeklyProductUpdates ) {
      return <WeeklyUpdates productUpdates={weeklyProductUpdates}/>
    }
  }

  renderProductLibrary() {
    const { weeklyProductUpdates } = this.props;

    if ( weeklyProductUpdates.product_library) {
      return <ProductLibrary productLibrary={weeklyProductUpdates.product_library}/>
    }
  }

  nextResult() {
    const { searchResultsWeeks } = this.props;
    const { currentWeekIndex } = this.state;
    return ( currentWeekIndex ? currentWeekIndex -1 : null );
  }

  prevResult() {
    const { searchResultsWeeks } = this.props;
    const { currentWeekIndex } = this.state;
    const maxIndex = searchResultsWeeks.length - 1;
    return ( currentWeekIndex < maxIndex  ? currentWeekIndex + 1 : null );
  }

  renderHeading() {
    return (
      <h1 className="home-heading">UPDATES BY WEEK.</h1>
    );
  }

  renderSearchResults() {
    const { toggleSearch } = this.props;
    const searchClasses = classnames('search-header', {'is-sticky': this.state.isSticky});
    const { currentWeek } = this.state;

    return (
      <div className="search-results">
        <h1 class="app-title">BMW PRODUCT UPDATES.</h1>
        {this.renderHeading()}
        <div className={searchClasses} ref="searchHeader">
          <SearchCriteria toggleSearch={toggleSearch} />
          <TimelineNavigation
            top={this.state.height}
            nextResult={this.nextResult()}
            prevResult={this.prevResult()}
            currentWeek={currentWeek}
            onChange={this.setCurrentWeek} />
        </div>
        {this.renderProductLibrary()}
        {this.renderWeeklyUpdates()}
      </div>
    );
  }

  renderNoResults() {
    const { toggleSearch } = this.props;
    const searchClasses = classnames('search-header', {'is-sticky': this.state.isSticky});

    return (
      <div className="search-results">
        {this.renderHeading()}
        <div className={searchClasses} ref="searchHeader">
          <SearchCriteria toggleSearch={toggleSearch} />
        </div>
        <h2 className="no-results-heading">No results found</h2>
      </div>
    );
  }

  render() {
    const { weeklyProductUpdates } = this.props;

    if (weeklyProductUpdates) {
      return this.renderSearchResults();
    } else {
      return this.renderNoResults();
    }
  }
}

SearchResults.propTypes = {
  bodyCode: PropTypes.number,
  toggleSearch: PropTypes.func.isRequired,
  searchResultsWeeks: PropTypes.arrayOf(PropTypes.shape({
    year: PropTypes.number.isRequired,
    week: PropTypes.number.isRequired
  })),
  textSearch: PropTypes.string
};

const mapStateToProps = ({ bodyCode, searchResultsWeeks, weeklyProductUpdates, textSearch }) => {
  return { bodyCode, searchResultsWeeks, weeklyProductUpdates, textSearch };
};

export default connect(mapStateToProps)(SearchResults);
