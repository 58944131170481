import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux'
import highlights from './highlights';
import series from './series';
import bodyCode from './body-code';
import bodyCodeWeeks from './body-code-weeks';
import searchResultsWeeks from './search-results-weeks';
import weeklyProductUpdates from './weekly-product-updates';
import searchCriteria from './search-criteria';
import modal from './modal';
import textSearch from './text-search';

export default combineReducers({
  highlights,
  router,
  series,
  bodyCode,
  bodyCodeWeeks,
  searchResultsWeeks,
  weeklyProductUpdates,
  searchCriteria,
  modal,
  textSearch
});
