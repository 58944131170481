import { first } from 'lodash/array';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Route } from 'react-router';
import reduxThunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import reducers from './reducers/index';
import Home from './views/home';
import { load as loadHighlights } from './actions/highlights';
import { load as loadSeries } from './actions/series';

const history = createBrowserHistory();
const middlewares = [
  routerMiddleware(history),
  reduxThunkMiddleware
];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger({
    // logger: undefined, // remove to enable logging
    collapsed: true
  }));
}

const store = createStore(
  reducers,
  applyMiddleware(...middlewares)
);


document.addEventListener('DOMContentLoaded', () => {
  const appRoot = first(document.getElementsByTagName('main'));

  if (appRoot) {
    store.dispatch(loadHighlights());
    store.dispatch(loadSeries());

    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route exact path="/" component={Home} />
        </ConnectedRouter>
      </Provider>,
      appRoot
    )
  }
});
