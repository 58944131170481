import { SET_WEEKLY_PRODUCT_UPDATES } from '../actions/weekly-product-updates';

function weeklyProductUpdates(state = null, action) {
  switch (action.type) {
    case SET_WEEKLY_PRODUCT_UPDATES:

      return action.weeklyProductUpdates
    default:
      return state;
  }
};

export default weeklyProductUpdates;
