import { Cipher } from "crypto";

const month_num_to_name_map = [
  '--',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const month_num_to_short_month_map = [
  '--',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Oct',
  'Nov',
  'Dec'
]

const week_to_month_map = [
  '--',
  'January',
  'January',
  'January',
  'January',
  'January',
  'February',
  'February',
  'February',
  'February',
  'March',
  'March',
  'March',
  'March',
  'April',
  'April',
  'April',
  'April',
  'May',
  'May',
  'May',
  'May',
  'June',
  'June',
  'June',
  'June',
  'June',
  'July',
  'July',
  'July',
  'July',
  'July',
  'August',
  'August',
  'August',
  'August',
  'September',
  'September',
  'September',
  'September',
  'October',
  'October',
  'October',
  'October',
  'October',
  'November',
  'November',
  'November',
  'November',
  'December',
  'December',
  'December',
  'December',
  'December'
]

const week_to_short_month_map = [
  '--',
  'Jan',
  'Jan',
  'Jan',
  'Jan',
  'Jan',
  'Feb',
  'Feb',
  'Feb',
  'Feb',
  'Mar',
  'Mar',
  'Mar',
  'Mar',
  'Apr',
  'Apr',
  'Apr',
  'Apr',
  'May',
  'May',
  'May',
  'May',
  'Jun',
  'Jun',
  'Jun',
  'Jun',
  'Jun',
  'Jul',
  'Jul',
  'Jul',
  'Jul',
  'Jul',
  'Aug',
  'Aug',
  'Aug',
  'Aug',
  'Sept',
  'Sept',
  'Sept',
  'Sept',
  'Oct',
  'Oct',
  'Oct',
  'Oct',
  'Oct',
  'Nov',
  'Nov',
  'Nov',
  'Nov',
  'Dec',
  'Dec',
  'Dec',
  'Dec',
  'Dec'
]

function getMonthOfISOWeek(w, _) {
  return week_to_month_map[w];
}

function getNameOfMonthNum(m, _) {
  return month_num_to_name_map[m];
}

function getShortNameOfMonthNum(m, _) {
  return month_num_to_name_map[m];
}

function getShortMonthOfISOWeek(w, _) {
  return week_to_short_month_map[w];
}

export default {getMonthOfISOWeek, getShortMonthOfISOWeek, getNameOfMonthNum, getShortNameOfMonthNum};
