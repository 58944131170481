import React, { Component } from 'react';
import TimelineButton from './timeline-button';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import dates from '../../utils/date';

class TimelineNavigation extends Component {
  isFutureWeek() {
    const thisWeek = moment().isoWeek();
    const thisYear = moment().year();
    const { week, year } = this.props.currentWeek;

    return year > thisYear || year === thisYear && week > thisWeek;
  }

  render() {
    const { year, month, week } = this.props.currentWeek || { year: null, week: null };
    const timelineClasses = classnames('timeline-navigation', { 'future' : this.isFutureWeek() });
    const monthName = dates.getNameOfMonthNum(month);

    return (
      <div className={timelineClasses} style={{top: this.props.top || "auto"}}>
        <TimelineButton next={this.props.prevResult} onClick={this.props.onChange} direction="prev" />
        <div className="week">WEEK {week} - {monthName}, {year}</div>
        <TimelineButton next={this.props.nextResult} onClick={this.props.onChange} direction="next" />
      </div>
    );
  }
}

TimelineNavigation.propTypes = {
  top: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  prevResult: PropTypes.number,
  nextResult: PropTypes.number
};

export default TimelineNavigation;
