import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import { setModalStatus } from '../../actions/modal';
import ChevronRight from '../../icons/chevron-right.svg';

class ModalButton extends Component {
  constructor () {
    super();

    bindAll(this, 'handleOpenModal');
  }

  handleOpenModal() {
    const { dispatch, content, type } = this.props;

    dispatch(setModalStatus({
      'modalOpen': true,
      type,
      content
    }));
  }

  render() {
    return (
      <button className="details-btn chevron-link" onClick={this.handleOpenModal}>
        <ChevronRight /> Details
      </button>
    );
  }
}

ModalButton.propTypes = {
  content: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

const mapStateToProps = ({ modal }) => {
  return { modal }
};

export default connect(mapStateToProps)(ModalButton);
