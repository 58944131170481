import { SET_SEARCH_CRITERIA } from '../actions/search-criteria';

function initialState() {
  return null;
}

function searchCriteriaReducer(state = initialState(), { type, searchCriteria }) {
  switch (type) {
    case SET_SEARCH_CRITERIA:
      return searchCriteria
    default:
      return state;
  }
};

export default searchCriteriaReducer;
