import weeksApi from '../api/body-code-weeks';

export const SET_TEXT_SEARCH = 'SET_TEXT_SEARCH';

export function setTextSearch(text) {
  return {
    type: SET_TEXT_SEARCH,
    text
  };
}

export function fetchTextSearchWeeks(bodyCode, text) {
  return weeksApi.fetch(bodyCode, text);
}

