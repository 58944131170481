export const SET_MODAL = 'SET_MODAL';
export const TOGGLE_TERMS_VISIBILITY = 'TOGGLE_TERMS';

export function setModalStatus(modal = {}) {
  return {
    type: SET_MODAL,
    modal
  }
}

export function toggleTermsVisibility() {
  return {
    type: TOGGLE_TERMS_VISIBILITY
  }
}
