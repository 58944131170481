import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import dates from '../../utils/date';

class Highlights extends Component {

  render() {
    return (
      <div className="product-highlights">
        <h1 class="app-title">BMW PRODUCT UPDATES.</h1>
        <h1>PRODUCT UPDATE HIGHLIGHTS.</h1>
        {this.renderHighlights()}
      </div>
    );
  }

  renderHighlights() {
    return (
      <ul className="highlights">
        {this.props.highlights.map((highlight, index) =>
          <li className="highlight" key={highlight.id}>
            <h3>WEEK {highlight.week} - {dates.getNameOfMonthNum(highlight.month, highlight.year)}</h3>
            <span className="index">{highlight.title}</span>
            <div className="md"><ReactMarkdown source={highlight.note} /></div>
          </li>
        )}
      </ul>
    );
  }
}

const mapStateToProps = state => (
  {
    highlights: state.highlights
  }
);

Highlights.propTypes = {
  highlights: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(Highlights);
