import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, map } from 'lodash/collection';
import { bindAll } from 'lodash/util';
import { pick } from 'lodash';
import SelectComponent from '../forms/select';
import SearchButton from './search-button';
import SearchReveal from './search-reveal';
import {
  set as setBodyCode,
  getWeeks as getBodyCodeWeeks,
  setWeeks as setBodyCodeWeeks
} from '../../actions/body-code'
import classnames from 'classnames';
import { setResultsWeeksFromTextSearch, setResultsWeeksFromSearch } from '../../actions/search-results-weeks';
import { setSearchCriteria } from '../../actions/search-criteria';
import { setTextSearch } from '../../actions/text-search';
import dates from '../../utils/date';

const toOption = i => ({ value: i.id, label: i.name });

class Search extends Component {
  constructor(props) {
    super(props);

    bindAll(
      this,
      'onBodyChange',
      'onSeriesChange',
      'onFromYearChange',
      'onFromWeekChange',
      'onToYearChange',
      'onToWeekChange',
      'onSearch',
      'onTextSearchChange',
      'setResultsWeeks',
      'setResultsSearchCriteria',
      'handleKeyPress',
      'buttonDisabled'
    );

    this.initialDateValues = {
      selectedFromYear: null,
      selectedFromJDate: null,
      selectedToYear: null,
      selectedToJDate: null,
      fromWeekOptions: null,
      toWeekOptions: null,
      fromValid: true,
      toValid: true,
      weeksValid: true,
      from: {},
      to: {}
    };

    this.state = {
      selectedSeries: null,
      selectedSeriesLabel: null,
      selectedBodyLabel: null,
      ...this.initialDateValues
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.series != this.props.series) {
      const seriesOptions = map(nextProps.series, toOption);

      this.setState({ seriesOptions });
    }
  }

  onSearch() {
    const { bodyCode, dispatch, bodyCodeWeeks, onSearch } = this.props;

    if ( bodyCode ) {
      this.setResultsWeeks();
      this.setResultsSearchCriteria();
      onSearch();
    }
  }

  setResultsSearchCriteria() {
    const { dispatch, textSearch } = this.props;
    const searchCriteria = pick(this.state, [
      'selectedSeriesLabel',
      'selectedBodyLabel',
      'selectedFromJDate',
      'selectedToJDate'
    ]);
    searchCriteria.textSearch = textSearch;

    dispatch(setSearchCriteria(searchCriteria));
  }

  setResultsWeeks() {
    const { bodyCode, bodyCodeWeeks, dispatch, textSearch } = this.props;
    const { from, to } = this.state;

    if (textSearch) {
      dispatch(setResultsWeeksFromTextSearch(bodyCode, textSearch, from, to));
    } else {
      dispatch(setResultsWeeksFromSearch(bodyCodeWeeks.weeks, from, to));
    }
  }

  onBodyChange(newValue) {
    const { value, label } = (newValue || {});
    const { dispatch } = this.props;

    dispatch(setBodyCode(value));

    if ( value ) {
      dispatch(getBodyCodeWeeks(value));
    } else {
      dispatch(setBodyCodeWeeks());
    }

    this.setState({
      selectedBodyLabel: label,
      ...this.initialDateValues
    });
  }

  onSeriesChange({ value, label }) {
    const selectedSeries = find(this.props.series, { id: value });
    const bodyOptions = map(selectedSeries.body_codes, toOption);

    this.setState({
      selectedSeries: value,
      selectedSeriesLabel: label,
      bodyOptions,
      ...this.initialDateValues
    });
  }

  onFromYearChange({ value: year }) {
    this.setState({
      from: {},
      selectedFromYear: year,
      selectedFromJDate: null,
      fromValid: false,
      weeksValid: false
    });

    this.getWeekList(year, "fromWeekOptions");
  }

  onFromWeekChange({ value: jDate }) {
    const { selectedFromYear: year } = this.state;
    const validateWeeks = this.validateWeeks(jDate, this.state.selectedToJDate);

    const week  = parseInt(jDate.substr(6, 2));
    const month = parseInt(jDate.substr(4, 2));
    
    this.setState({
      from: { year, month, week },
      selectedFromJDate: jDate,
      fromValid: true,
      weeksValid: validateWeeks
    });
  }

  onToYearChange({ value: year }) {
    this.setState({
      to: {},
      selectedToYear: year,
      selectedToJDate: null,
      toValid: false,
      weeksValid: false
    });

    this.getWeekList(year, "toWeekOptions");
  }

  onToWeekChange({ value: jDate }) {
    const { selectedToYear: year } = this.state;
    const validateWeeks = this.validateWeeks(this.state.selectedFromJDate, jDate);
    let week, month;

    if (jDate != null) {
      week  = parseInt(jDate.substr(6, 2));
      month = parseInt(jDate.substr(4, 2));
    } else {
      week = null;
      month = null;
    }
    
    this.setState({
      to: { year, month, week },
      selectedToJDate: jDate,
      toValid: true,
      weeksValid: validateWeeks
    });
  }

  validateWeeks(from, to) {
    if (this.state.selectedFromYear > this.state.selectedToYear) { return false };
    if (this.state.selectedFromYear < this.state.selectedToYear) { return true };
    if (this.state.selectedFromYear === this.state.selectedToYear) {
      return (!from || !to) || from <= to;
    };
  }

  getYearList() {
    const { bodyCodeWeeks } = this.props;

    if (!this.props.bodyCode) {
      return null;
    }

    if (bodyCodeWeeks) {
      return bodyCodeWeeks.yearList;
    }
  }

  getWeekList(selectedYear, menu) {
    const { bodyCodeWeeks } = this.props;
    const groupByYear = bodyCodeWeeks.weeks.reduce((a, b) => (
      a[b.year] = a[b.year] || [],
      a[b.year].push({
        label: this.getWeekLabel(b),
        value: this.getJDate(b)
      }), a
    ), {});

    this.setState({
      [menu]: groupByYear[selectedYear]
    });
  }

  getJDate(date) {
    return `${date.year * 10000 + date.month * 100 + date.week}`
  }

  getWeekLabel(date) {
    return `${date.week} - ${dates.getShortNameOfMonthNum(date.month)}`;
  }

  onTextSearchChange(event) {
    const { dispatch } = this.props;
    dispatch(setTextSearch(event.target.value));
  }

  handleKeyPress(event) {
    if(event.key === 'Enter' && !this.buttonDisabled()) {
      this.onSearch();
    };
  }

  buttonDisabled() {
    return (
      !this.props.bodyCode || 
      !this.state.fromValid || 
      !this.state.toValid || 
      !this.state.weeksValid
    );
  }

  render() {
    const {
      bodyCode,
      onSearch,
      series,
      toggleSearch,
      bodyCodeWeeks,
      textSearch
    } = this.props;

    const {
      bodyOptions,
      selectedSeries,
      selectedSeriesLabel,
      seriesOptions,
      selectedFromYear,
      selectedFromJDate,
      selectedToYear,
      selectedToJDate,
      fromWeekOptions,
      toWeekOptions,
      fromValid,
      toValid
    } = this.state

    return (
      <div className="search-wrapper">
        <SearchReveal onToggle={toggleSearch} />
        <div className="search-form">
          <h2>SEARCH FOR PRODUCT UPDATES.</h2>
          <div className="search-row series">
            <SelectComponent
              onChange={this.onSeriesChange}
              options={seriesOptions}
              value={selectedSeries}
              label={selectedSeriesLabel}
              placeholder="Series"
            />
          </div>
          <div className="search-row body-code">
            <SelectComponent
              onChange={this.onBodyChange}
              options={bodyOptions}
              value={bodyCode}
              placeholder="Body group/style"
              name="BODY"
            />
          </div>

          <hr className="search-divider" />

          <div className="search-row columns">
            <div className="search-col">
              <div className="label">From</div>
              <div className="select-boxes">
                <SelectComponent
                  selectClass="from-year"
                  onChange={this.onFromYearChange}
                  options={this.getYearList()}
                  value={selectedFromYear}
                  placeholder="Year"
                />
                <SelectComponent
                  selectClass="from-week"
                  onChange={this.onFromWeekChange}
                  options={fromWeekOptions}
                  value={selectedFromJDate}
                  placeholder="Week"
                />
              </div>
            </div>
            <div className="search-col">
              <div className="label">To</div>
              <div className="select-boxes">
                <SelectComponent
                  selectClass="to-year"
                  onChange={this.onToYearChange}
                  options={this.getYearList()}
                  value={selectedToYear}
                  placeholder="Year"
                />
                <SelectComponent
                  selectClass="to-week"
                  onChange={this.onToWeekChange}
                  options={toWeekOptions}
                  value={selectedToJDate}
                  placeholder="Week"
                />
              </div>
            </div>
          </div>
          <div className="search-row">
            <input 
              className="text-search"
              type="text"
              value={textSearch}
              placeholder="Enter keywords"
              onChange={this.onTextSearchChange}
              onKeyPress={this.handleKeyPress}
            />
          </div>
          <div className="search-row search-button-row">
            <SearchButton
              onClick={this.onSearch}
              buttonDisabled={this.buttonDisabled()}/>
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    body_codes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })).isRequired
  })).isRequired,
  bodyCode: PropTypes.number,
  bodyCodeWeeks: PropTypes.shape({
    weeks: PropTypes.arrayOf(PropTypes.shape({
      year: PropTypes.number.isRequired,
      week: PropTypes.number.isRequired
    })),
    yearList: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired
    }))
  }),
  textSearch: PropTypes.string
};

const mapStateToProps = ({ bodyCode, bodyCodeWeeks, series, textSearch }) => {
  return {
    bodyCode,
    bodyCodeWeeks,
    series,
    textSearch
  }
};

export default connect(mapStateToProps)(Search);
