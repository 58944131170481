import dispatcher from '../utils/action/dispatcher';
import weeksApi from '../api/body-code-weeks';

export const SET_BODY_CODE = 'SET_BODY_CODE';
export const SET_BODY_CODE_WEEKS = 'SET_BODY_CODE_WEEKS';

export function set(value = null) {
  return {
    type: SET_BODY_CODE,
    value
  };
}

export function getWeeks(bodyCode) {
  return dispatch => weeksApi.fetch(bodyCode).then(dispatcher(dispatch, SET_BODY_CODE_WEEKS, 'weeks'));
}

export function setWeeks(value = []) {
  return {
    type: SET_BODY_CODE_WEEKS,
    value
  };
}
