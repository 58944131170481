import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UpdateIcon from '../../icons/update.svg';
import ModalButton from './modal-button.jsx';

const TITLE = {
  standard: "STANDARD EQUIPMENT",
  optional: "OPTIONAL EQUIPMENT"
}

class Equipment extends Component {

  renderModalButton(item) {
    return (
      <ModalButton type="equipment" content={item} />
    );
  }

  renderTitle() {
    return TITLE[this.props.type];
  }

  render() {
    return (
      <div className={`weekly-section equipment ${this.props.type}`}>
        <h3>{this.renderTitle()}.</h3>
        {this.props.items.map((item) =>
          <div className={`change-item ${item.change_type}`} key={item.id}>
            <h3 className={`type ${item.change_type}`}>{item.change_type}</h3>
            <p className="title"><UpdateIcon className="update-icon" />{item.title}</p>
            <p className="code"><strong>Affected models:</strong> {item.models}</p>
            <p className="code"><strong>Option code:</strong> {item.option_code}</p>
            {this.renderModalButton(item)}
          </div>
        )}
      </div>
    );
  }
}

Equipment.propTypes = {
  type: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    change_type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired
};

export default Equipment;
