import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { bindAll } from 'lodash/util';
import PropTypes from 'prop-types';
import { setModalStatus } from '../../actions/modal';
import Equipment from '../modals/equipment';
import Lineup from '../modals/lineup';
import Prices from '../modals/prices';
import Close from '../../icons/close.svg';

const MODAL_CLASSES = {
  portalClassName: 'react-modal',
  className: {
    base: 'react-modal-window',
    afterOpen: 'after-open',
    beforeClose: 'before-close'
  },
  overlayClassName: {
    base: 'react-modal-overlay',
    afterOpen: 'after-open',
    beforeClose: 'before-close'
  }
};

class Modal extends Component {
  constructor () {
    super();

    bindAll(this, 'handleModalClose');
  }
  
  handleModalClose() {
    const { dispatch } = this.props;

    dispatch(setModalStatus({ 'modalOpen': false }));
  }

  renderEquipmentContent() {
    const { type } = this.props.modal;

    if (type === "equipment") {
      return ( <Equipment /> );
    }
  }

  renderLineupContent() {
    const { type } = this.props.modal;

    if (type === "lineup") {
      return ( <Lineup /> );
    }
  }

  renderPricesContent() {
    const { type } = this.props.modal;

    if (type === "prices") {
      return ( <Prices /> );
    }
  }

  render() {
    const { modalOpen, termsVisible } = this.props.modal;
    const { portalClassName, className, overlayClassName } = MODAL_CLASSES;

    return (
      <ReactModal 
        isOpen={modalOpen}
        ariaHideApp={false}
        closeTimeoutMS={200}
        portalClassName={portalClassName}
        className={className}
        overlayClassName={overlayClassName}
        onRequestClose={this.handleModalClose}
        shouldCloseOnOverlayClick={true}
      >

        <div className="modal-content">
          <button className="close-modal-btn" onClick={this.handleModalClose}><Close /></button>
          {this.renderEquipmentContent()}
          {this.renderLineupContent()}
          {this.renderPricesContent()}
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string
  })
};

const mapStateToProps = ({ modal }) => {
  return { modal }
};

export default connect(mapStateToProps)(Modal);
