import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import ReactMarkdown from 'react-markdown';

class Notes extends Component {
  render() {
    return (
      <div className="weekly-section model-notes">
        <h3>MODEL NOTES.</h3>
        {this.props.items.map((item) =>
          <div className="change-item" key={item.id}>
            <div className="md"><ReactMarkdown source={item.note} /></div>
          </div>
        )}
      </div>
    );
  }
}

Notes.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    note: PropTypes.string.isRequired
  })).isRequired
};

export default Notes;
