import { SET_BODY_CODE } from '../actions/body-code';

function initialState() {
  return null;
}

function bodyCodeReducer(state = initialState(), { type, value }) {
  switch (type) {
    case SET_BODY_CODE:
      return value
    default:
      return state;
  }
};

export default bodyCodeReducer;
