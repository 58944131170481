import { SET_SEARCH_RESULTS_WEEKS } from '../actions/search-results-weeks';

function initialState() {
  return [];
}

function searchResultsWeeksReducer(state = initialState(), { type, weeks }) {
  switch (type) {
    case SET_SEARCH_RESULTS_WEEKS:
      return weeks;
    default:
      return state;
  }
};

export default searchResultsWeeksReducer;
