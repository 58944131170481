import React, { Component } from 'react';
import { bindAll } from 'lodash/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ChevronLeft from '../../icons/chevron-left.svg';
import ChevronRight from '../../icons/chevron-right.svg';

const ICONS = {
  next: ChevronRight,
  prev: ChevronLeft
}

class TimelineButton extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onClick');
  }

  onClick() {
    this.props.onClick(this.props.next);
  }

  renderIcon(type) {
    const Icon = ICONS[type];

    return <Icon />;
  }

  render() {
    const { direction } = this.props;
    const navClasses = classnames('nav-btn', `nav-btn nav-${direction}`);

    return (
      <button className={navClasses} onClick={this.onClick} disabled={!Number.isInteger(this.props.next)}>
        {this.renderIcon(direction)}
      </button>
    );
  }
}

TimelineButton.propTypes = {
  direction: PropTypes.string.isRequired
}

export default TimelineButton;
