import dispatcher from '../utils/action/dispatcher';
import weeksApi from '../api/weekly-product-updates';
import { isEmpty } from 'lodash/lang';
import { fetchTextSearchWeeks } from './text-search';

export const SET_SEARCH_RESULTS_WEEKS = 'SET_SEARCH_RESULTS_WEEKS';

export function setResultsWeeksFromTextSearch(bodyCode, textSearch, from, to) {
  return (dispatch) => {
    fetchTextSearchWeeks(bodyCode, textSearch).then((weeks) =>
      dispatch(setSearchResultsWeeks(filterWeeks(weeks, from, to)))
    );
  }
}

export function setResultsWeeksFromSearch(weeks, from, to) {
  return (dispatch) => {
    dispatch(setSearchResultsWeeks(filterWeeks(weeks, from, to)));
  }
}

function setSearchResultsWeeks(weeks) {
  return {
    type: SET_SEARCH_RESULTS_WEEKS,
    weeks
  }
}

function weekComparator(b, a) {
  const yearComp  = Math.sign(b.year  - a.year);
  const monthComp = Math.sign(b.month - a.month);
  const weekComp  = Math.sign(b.week  - a.week);

  return (yearComp ? yearComp : (monthComp ? monthComp : weekComp));
}

function weeksFrom(weeks, from) {
  return weeks.filter(
    (item) => { return (weekComparator(item, from) >= 0); }
  );
}

function weeksTo(weeks, to) {
  return weeks.filter(
    (item) => { return (weekComparator(item, to) <= 0); }
  );
}

function filterWeeks(weeks, from, to) {
  weeks = !isEmpty(from) ? weeksFrom(weeks, from) : weeks;
  weeks = !isEmpty(to) ? weeksTo(weeks, to) : weeks;
  return weeks;
}
