import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash/util';
import Chevron from '../../icons/chevron-down.svg';

class SelectComponent extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onChange');
  }

  onChange(newValue) {
    const { onChange, value } = this.props;
    
    if (newValue) {
      if (newValue.value != value) {
        onChange(newValue);
      }
    } else {
      onChange();
    }
  }

  arrowRenderer() {
    return <span><Chevron /></span>;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.name === "BODY") {
      if (nextProps.options !== this.props.options) {
        this.onChange();
      }
    }
  }

  render() {
    const { selectClass, name, options, placeholder, value } = this.props;

    return (
      <Select
        onChange={this.onChange}
        options={options}
        value={value}
        clearable={false}
        searchable={false}
        name={name}
        placeholder={placeholder}
        arrowRenderer={this.arrowRenderer}
        className={selectClass}
      />
    );
  }
}

SelectComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default SelectComponent;
