import { SET_BODY_CODE_WEEKS } from '../actions/body-code';

function initialState() {
  return null;
}

function getYearList(weeks = []) {
  const uniqueYears = [ ...new Set(weeks.map(item => item.year)) ];
  return uniqueYears.map(val => {
    return({
      label: val,
      value: val
    })
  });
}

function bodyCodeWeeksReducer(state = initialState(), { type, weeks }) {
  switch (type) {
    case SET_BODY_CODE_WEEKS:
      return ({
        weeks,
        yearList: getYearList(weeks)
      });
    default:
      return state;
  }
};

export default bodyCodeWeeksReducer;
