import { LOAD_SERIES_SUCCESS } from '../actions/series';

function initialState() {
  return [];
}

function seriesReducer(state = initialState(), { type, series }) {
  switch (type) {
    case LOAD_SERIES_SUCCESS:
      return series
    default:
      return state;
  }
};

export default seriesReducer;
