import React, { Component } from 'react';
import HomeIcon from '../../icons/home.svg';
import BmwImg from '../../icons/bmw-roundel.svg';

class Header extends Component {
  render() {
    return (
      <header className="main-header">
        <div className="container">
          <a href="/" className="home-link">
            <HomeIcon /> Home
          </a>
          <a href="/" className="logo">
            <BmwImg />
          </a>
        </div>
      </header>
    );
  }
}

export default Header;
