import rp from 'request-promise';

// IE fix for location origin
const { origin, protocol, hostname, port } = window.location;

const getHost = () => (origin || `${protocol}//${hostname}${port ? `:${port}` : ''}`);

export default {
  fetch: (path, qs) => rp({
    uri: `${getHost()}/${path}`,
    json: true,
    qs
  })
};
