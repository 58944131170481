import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { bindAll } from 'lodash/util';
import { isEmpty } from 'lodash';
import { toggleTermsVisibility } from '../../actions/modal';

class EquipmentModal extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onClickPriceProtectionToggle', 'renderPriceProtectionContent');
  }

  onClickPriceProtectionToggle(ev) {
    ev.preventDefault();
    
    const { dispatch } = this.props;

    dispatch(toggleTermsVisibility());
  }

  renderImages(images) {
    return images.map(({ image: { url }, description }, index) =>
      <figure key={index}>
        <img src={url} alt={description} />
      </figure>
    );
  }

  renderPriceProtectionContent() {
    const { content, termsVisible } = this.props.modal;
    
    let valueContent, termsContent, termsSection;

    if (content.price_protection_value == '') {
      valueContent = '';
    } else {
      valueContent = (
        <ul className="pricelist">
          <li>
            <span>Price protection value</span>
            <span>{content.price_protection_value}</span>
          </li>
        </ul>);
    }

    if (isEmpty(content.price_protection_terms)) {
      termsSection = '';
    } else {
      if (termsVisible) {
        termsContent = (<div className="price-protection-content"><ReactMarkdown source={content.price_protection_terms} /></div>);
      } else {
        termsContent = '';
      }

      termsSection = (<div className="price-protection-section closed">
        <a className="price-protection-toggle" href="#" onClick={this.onClickPriceProtectionToggle}>Price Protection Terms</a>
        {termsContent}
      </div>);
    }

    return (
      <div className="price-protection">
        {valueContent}
        {termsSection}
      </div>
    )

  }

  render() {
    const { type, content } = this.props.modal;

    return (
      <div className={type}>
        <header>
          <h3 className="change-type">{content.change_type}</h3>
          <div className="affected-models"><strong>Affected models:</strong> {content.models}</div>
          <div className="option-code"><strong>Option code:</strong> {content.option_code}</div>
        </header>
        <div className="content">
          <h2>{content.title}</h2>
          <div className="description md"><ReactMarkdown source={content.description} /></div>
          <div className="images">{this.renderImages(content.images)}</div>
          {this.renderPriceProtectionContent(content)}
        </div>
      </div>
    );
  }
}

EquipmentModal.propTypes = {
  modal: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.shape({
      change_type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  })
};

const mapStateToProps = ({ modal }) => {
  return { modal }
};

export default connect(mapStateToProps)(EquipmentModal);
