import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModelAdditionIcon from '../../icons/model-addition.svg';
import ModelDeletionIcon from '../../icons/model-deletion.svg';
import ModalButton from './modal-button.jsx';

const ICONS = {
  addition: ModelAdditionIcon,
  discontinued: ModelDeletionIcon
}

class Lineup extends Component {
  renderModalButton(item) {
    if (item.status === "addition") {
      return (
        <ModalButton type="lineup" content={item} />
      );
    }
  }

  render() {
    return (
      <div className="weekly-section lineup">
        <h3>MODEL LINE-UP.</h3>
        {this.props.items.map((item) =>
          <div className={`change-item ${item.status}`} key={item.id}>
            <h3 className={`type ${item.status}`}>{this.renderLineupIcon(item.status)} {item.name}</h3>
            <p className="code"><strong>Model code:</strong> {item.model_code}</p>
            {this.renderModalButton(item)}
          </div>
        )}
      </div>
    );
  }

  renderLineupIcon(type) {
    const Icon = ICONS[type];

    return <Icon />;
  }
}

Lineup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    model_code: PropTypes.string.isRequired
  })).isRequired
};

export default Lineup;
