import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Equipment from './equipment';
import Lineup from './lineup';
import Prices from './prices';
import Notes from './notes';
import Modal from './modal';

class WeeklyUpdates extends Component {

  renderStandardEquipment() {
    const { standard_equipment } = this.props.productUpdates;

    if (standard_equipment.length) {
      return (
        <Equipment 
          items={standard_equipment}
          type="standard" />
      );
    }
  }

  renderOptionalEquipment() {
    const { optional_equipment } = this.props.productUpdates;

    if (optional_equipment.length) {
      return (
        <Equipment 
          items={optional_equipment}
          type="optional" />
      );
    }
  }

  renderLineup() {
    const { lineups } = this.props.productUpdates;

    if (lineups.length) {
      return <Lineup items={lineups} />;
    }
  }

  renderPrices() {
    const { prices } = this.props.productUpdates;

    if (prices.length) {
      return <Prices items={prices} />;
    }
  }

  renderNotes() {
    const { model_notes } = this.props.productUpdates;

    if (model_notes.length) {
      return <Notes items={model_notes} />;
    }
  }

  render() {
    return (
      <div className="weekly-updates">
        {this.renderNotes()}
        {this.renderStandardEquipment()}
        {this.renderOptionalEquipment()}
        {this.renderLineup()}
        {this.renderPrices()}

        <Modal />
      </div>
    );
  }
}

WeeklyUpdates.propTypes = {
  productUpdates: PropTypes.shape({
    model_notes: PropTypes.array,
    standard_equipment: PropTypes.array,
    optional_equipment: PropTypes.array,
    lineups: PropTypes.array
  })
};

export default WeeklyUpdates;
