import { LOAD_HIGHLIGHTS_SUCCESS } from '../actions/highlights';

function initialState() {
  return [];
}

function highlightsReducer(state = initialState(), { type, highlights }) {
  switch (type) {
    case LOAD_HIGHLIGHTS_SUCCESS:
      return highlights
    default:
      return state;
  }
};

export default highlightsReducer;
