import React, { Component } from 'react';
import Content from '../components/layout/content';
import Header from '../components/layout/header';
import { bindAll } from 'lodash/util';
import classnames from 'classnames';

class Home extends Component {
  constructor(props) {
    super(props);

    bindAll(this, 'onSearch');

    this.state = {
      showResults: false
    }
  }

  onSearch() {
    this.setState({ showResults: true });
  }

  render() {
    const wrapperClass = classnames('wrapper', { 'showing-results': this.state.showResults });

    return(
      <div className={wrapperClass}>
        <Header />
        <Content 
          onSearch={this.onSearch}
          showResults={this.state.showResults} />
      </div>
    );
  }
}

export default Home;
