import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '../../icons/chevron-right.svg';

class ProductLibrary extends Component {

  render() {
    const { productLibrary } = this.props;

    return (
      <div className="product-library">
        <a href={productLibrary.url} target="_blank" className="chevron-link">
          <ChevronRight /> View product library
        </a>
      </div>
    );
  }
}

ProductLibrary.propTypes = {
  productLibrary: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

export default ProductLibrary;
